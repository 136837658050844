<template>
  <el-drawer :visible.sync="drawerShow" :size="1200" @close="$emit('close')">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">周互动答题</div>
    </div>
    <div style="padding: 20px;">
      <div class="interact-tip">
        <p>提示：为方便老师上课时可随时发布课堂互动答题；您可以在此处提前设置需要互动的题目，快速使用教学。</p>
      </div>
      <div class="interact-flow">
        <img src="../../../assets/images/new_icon/liucheng.png" width="100%" />
      </div>
      <el-button type="primary" @click="add">添加互动答题</el-button>
      <el-table
          :data="tableData"
          border
          class="table"
      >
        <el-table-column
            prop="id"
            label="ID">
        </el-table-column>
        <el-table-column
            prop="name"
            label="互动答题名称"
        >
        </el-table-column>
        <el-table-column
            prop="data_count"
            label="试题数量">
        </el-table-column>
        <el-table-column
            prop="address"
            label="创建时间">
          <template slot-scope="scope">
            {{util.timeFormatter(new Date(+scope.row.created_at*1000), 'yyyy-MM-dd hh:mm')}}
          </template>
        </el-table-column>
        <el-table-column
            prop="address"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="start(scope.row)" class="op-btn">发起互动</el-button>
            <el-button type="primary" size="small" @click="goRecord(scope.row)" class="op-btn">互动记录</el-button>
            <el-button type="primary" size="small" @click="edit(scope.row)" class="op-btn">编辑</el-button>
            <el-button type="danger" size="small" @click="dele(scope.row)" class="op-btn">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
      <DeleModal :status="deleModalStatus" txt="确认删除吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
      <el-drawer
          :visible.sync="startInteractModal"
          title="发起互动答题"
          @on-cancel="closeStartInteractModal"
          footer-hide
          size="550px"
          :append-to-body="true"
      >
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">发起互动答题</div>
        </div>
        <div class="start-interact">
          <p class="start-interact-tit">请选择答题时长（默认为100分钟）</p>
          <el-select
              v-model="timelong"
              placeholder="请选择"
          >
            <el-option
                v-for="item in timelongList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <p class="start-interact-tit">请选择要发起互动答题的班级</p>
          <div class="start-interact-group-list" v-for="item in groupList" :key="item.id">
            <p>{{ item.group.name }}<span v-if="item.interact_last_choose == 1">（上次选择）</span></p>
            <el-button type="primary" size="small" @click="startInteract(item)">发起</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </el-drawer>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '@/components/deleteModal.vue';
export default {
  name: "interact.vue",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    courseId:{
      type:String,
      required:true
    },
    mapId:{
      type:String,
      required:true
    },
    taskId:{
      type:String,
      default: ''
    }
  },
  data(){
    return{
      drawerShow:false,
      util:util,
      tableData:[],
      page:1,
      pageSize:10,
      total:0,
      deleModalStatus:false,
      modalLoading:false,
      curData:{},
      startInteractModal:false,
      timelong:'100',
      timelongList:[],
      groupList:[],
    }
  },
  components:{
    DeleModal
  },
  created(){
    // this.courseId = this.$route.query.id || '';
    // this.getList();
    // this.getCourseForm();
  },
  methods:{
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        task_id:this.taskId,
      };
      this.api.course.courseInteractList(params).then((res)=>{
        if(this.page > 1 && !res.list.length){
          this.page = this.page - 1;
          this.getList();
        }else{
          this.tableData = res.list;
          this.total = Number(res.count);
        }
      })
    },
    add(){
      this.$router.push({
        path:'/course/interact/add',
        query:{
          mapId:this.mapId,
          courseId:this.courseId,
          taskId:this.taskId
        }
      })
    },
    edit(data){
      this.$router.push({
        path:'/course/interact/add',
        query:{
          mapId:this.mapId,
          courseId:this.courseId,
          interactId:data.id,
          taskId:this.taskId
        }
      })
    },
    dele(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let params = {
        interact_id:this.curData.id
      };
      this.modalLoading = true;
      this.api.course.courseInteractDelete(params).then((res)=>{
        this.modalLoading = false;
        this.$Message.success('删除成功');
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      })
    },
    closeStartInteractModal(){
      this.startInteractModal = false;
    },
    start(data){
      this.curData = data;
      this.startInteractModal = true;
      this.getGroupList();
    },
    getGroupList(){
      let params = {
        course_id: this.courseId,
        with_interact_last_choose:1,
        interact_id:this.curData.id
      };
      this.api.course.courseGroupList(params).then((res)=>{
        this.groupList = res.list;
      })
    },
    getCourseForm(){
      this.api.course.courseForm().then((res)=>{
        // this.timelongList = [{
        //   id:'90',
        //   name:'不限时'
        // }];
        this.timelongList = [];
        for(let name in res.timelongs){
          this.timelongList.push({
            id:name,
            name:res.timelongs[name]
          })
        }
      })
    },
    startInteract(data){
      let params = {
        interact_id:this.curData.id,
        timelong:this.timelong,
        group_id:data.group_id
      }
      this.api.course.courseInteractStart(params).then((res)=>{
        this.$Message.success('发起成功');
        this.$router.push({
          path:'/course/interact/detail',
          query:{
            id:res.interact_task.id
          },
        })
      })

    },
    goRecord(data){
      this.$router.push({
        path:'/course/interact/record',
        query:{
          id:data.id
        },
      })
    }
  },
  watch:{
    show(newVal){
      if(newVal){
        this.getList();
        this.getCourseForm();
      }
      this.drawerShow=newVal;
    }
  }
}
</script>

<style scoped lang="scss">
    .interact-tip{
      margin-bottom: 20px;
      padding-left: 14px;
      height: 40px;
      background: #F8F8F9;
      border: 1px dashed #CCCCCC;
      border-radius: 4px;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-left: 10px;
        padding: 0 10px;
        height: 24px;
        background: #5782FF;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .interact-flow{
      margin-bottom: 20px;
      padding: 20px 40px;
      background: #F8F8F9;
      border-radius: 4px;
    }
    .table{
      margin-top: 20px;
      width: 100%;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
    .start-interact{
      padding: 0 20px;
      font-size: 14px;
      .start-interact-tit{
        margin-top: 30px;
        margin-bottom: 14px;
      }
      .start-interact-group-list{
        margin-bottom: 10px;
        padding: 20px;
        background: #F6F7FA;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        >p{
          >span{
            color:#5578F6;
          }
        }

      }
    }
    .op-btn{
      margin-left: 0;
      margin-right: 10px;
      margin-bottom: 10px;
    }


</style>
