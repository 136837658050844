<template>
  <div>
    <el-button type="primary" @click="createTask">去创建周课任务 ></el-button>
    <el-button type="primary" :class="tableData.length > 0?'disabled':''" @click="toWeeklySchedules" :disabled="tableData.length > 0">章节转化为周课</el-button>

    <div class="directory">
      <div class="directory-item" v-for="(item, index) in tableData" :key="item.id">
        <div class="directory-item-title" @click="expand(item)">
          <div class="left">
            <div>第{{ index + 1 }}周：</div>
            <div style="margin-left: 5px;">{{ item.name }}</div>
          </div>
          <div class="right">
            <el-button class="el-icon-top" type="primary" size="small" v-if="index>0" @click.stop="moveTask(item,1)">上移</el-button>
            <el-button class="el-icon-bottom" type="primary" size="small" v-if="index+1<tableData.length" @click.stop="moveTask(item,2)">下移</el-button>
            <el-button type="primary" size="small" class="btn" @click.stop="createExam(item)">发布模考</el-button>
            <el-button type="primary" size="small" class="btn" @click.stop="openWeekTestList(item)">周模考列表</el-button>
            <el-button type="primary" size="small" class="btn" @click.stop="openWeekInteract(item)">周互动答题</el-button>
            <el-button type="primary" size="small" class="btn" @click.stop="edit(item)">编辑</el-button>
            <el-button type="danger" size="small" class="btn" style="margin-right: 10px;"
              @click.stop="dele(item)">删除</el-button>
            <img src="../../../../assets/images/new_icon/x.png" alt=""
              :style="{ transform: item.show ? 'rotate(180deg)' : 'rotate(0deg)' }">
          </div>
        </div>
        <div class="subsection-list" :style="{ maxHeight: item.show ? item.children_total_count * 70 + 'px' : '0px' }">
          <el-tree
              :data="item.children"
              node-key="node_child_id"
              label="name"
              default-expand-all
          >
          <div slot-scope="{node,data}" class="directory-subsection-item">
            <div class="sub-left">
              <div class="sub-title">{{ data.index < 9 ? "0" + (data.index + 1) : data.index + 1 }}&nbsp;&nbsp;{{ data.node.name }}</div>
              </div>
              <div class="sub-right"  v-if="!data.children || !data.children.length">
                <div class="sub-right-item" @click="viewData(data, 'ppt')" v-if="data.ppt_info.length > 0"><img class="icon" src="../../../../assets/images/course/ppt.png" alt="">课件<span>({{ data.ppt_info.length }}个)</span></div>
                <div class="sub-right-item" @click="viewData(data, 'video')" v-if="data.vedio_info.length > 0"><img class="icon" src="../../../../assets/images/course/video.png" alt="">视频<span>({{ data.vedio_info.length }}个)</span></div>
                <div class="sub-right-item" @click="viewData(data, 'enchiridion')" v-if="data.enchiridion_info.length > 0">
                  <img class="icon" src="../../../../assets/images/course/exper.png" alt="">实验手册<span>({{ data.enchiridion_info.length }}个)</span>
                </div>
                <div class="sub-right-item" @click="viewData(data, 'data')" v-if="data.data_count > 0"><img class="icon" src="../../../../assets/images/course/train.png" alt="">试题<span>({{data.have_finished || 0}}/{{ data.data_count }}题)</span></div>
              </div>
            </div>
          </el-tree>
          </div>
        </div>
      </div>

      <el-dialog title="请选择要创建的试卷类型" :visible.sync="dialogVisible" width="710px">
        <div class="exam-type">
          <div class="exam-type-item mr20" :class="examType == 2 ? 'active' : ''" @click="createExamType('2')">
            <img src="../../../../assets/images/new_icon/guding.png" width="224" height="132" />
            <p class="exam-type-item-tit">固定试题</p>
            <p>选择试题，每个学生的试卷试题一样</p>
            <p>可选择相同顺序/打乱顺序答题</p>
          </div>
          <div class="exam-type-item" :class="examType == 12 ? 'active' : ''" @click="createExamType('12')">
            <img src="../../../../assets/images/new_icon/suiji_icon.png" width="224" height="132" />
            <p class="exam-type-item-tit">随机出题</p>
            <p>设定题型难度规则，千人千卷</p>
            <p>每个学生试卷试题不一样</p>
          </div>
        </div>
      </el-dialog>
      <DeleModal :status="deleModalStatus" :txt="deleMessage" :tit="deleTit" :loading="modalLoading" @cancel="cancelDele"
        @confirm="confirmDele"></DeleModal>
      <Train :show="trainShow" :courseId="courseId" @close="trainShow = false" />
      <Exam :show="examShow" :courseId="courseId" :taskId="taskId" :mapId="mapId" @close="examShow = false" />
      <Inter :show="interactionShow" :courseId="courseId" :taskId="curData.id" :mapId="mapId" @close="interactionShow = false" />

      <el-drawer :visible.sync="drawerShow" size="80%" :destroy-on-close="true">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ drawerTitle }}</div>
        </div>
        <div style="padding: 10px 20px; height: calc(100% - 60px);">
          <div class="name-list">
            <div :class="curFileIndex == index ? 'active' : ''" v-for="(item, index) in fileList" :key="item.id"
              @click="changeFileName(index, item)">{{ item.name }}</div>
          </div>
          <div v-if="fileList.length && fileList[curFileIndex].path" style="height: 100%;">
            <video v-if="fileType == 'video'" ref="video" id="videoPlayer" :src="fileList[curFileIndex].path"
              class="video-js vjs-default-skin" style="background-color: black;" controls width="100%" height="100%"
              controlslist="nodownload"></video>
            <div v-if="fileType == 'enchiridion' && fileList[curFileIndex].link"
              style="font-size: 14px;margin-bottom: 10px">
              实操跳转链接：{{ fileList[curFileIndex].link }} <a :href="fileList[curFileIndex].link" target="_blank"
                style="color:#2d8cf0;text-decoration: underline;">点击跳转</a>
            </div>
            <embed v-if="fileType != 'video' && fileList[curFileIndex].path" id="iframe" ref="myIframe"
              :src="fileList[curFileIndex].path" width="100%" height="100%" type="application/pdf" />
          </div>
        </div>
      </el-drawer>
      <el-drawer :visible.sync="experDrawerShow" size="80%">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ drawerTitle }}</div>
        </div>
        <div class="experiment">
          <div class="experiment-pdf">
            <div class="experiment-pdf-top">
              <div class="name-list">
                <div :class="curFileIndex == index ? 'active' : ''" v-for="(item, index) in fileList" :key="item.id"
                  @click="changeFileName(index)">{{ item.name }}</div>
              </div>
              <div>
                <el-button v-for="item in experInfoList" :key="item.id" style="margin-left: 10px;"
                  :loading="experLinkLoading && item.id === loadingId" type="primary" size="small"
                  @click="openExperiment(item, true)">{{ item.name }}</el-button>
                <el-button v-for="item in linkInfoList" :key="item.id" style="margin-left: 10px;" type="primary"
                  size="small" @click="openExperiment(item)">{{ item.name }}</el-button>
              </div>
            </div>
            <div v-if="fileList.length && fileList[curFileIndex].path" style="height: calc(100% - 76px);">
              <embed v-if="fileType != 'video' && fileList[curFileIndex].path" id="iframe" ref="myIframe"
                :src="fileList[curFileIndex].path" width="100%" height="100%" type="application/pdf" />
            </div>
          </div>
        </div>
      </el-drawer>
      <el-drawer :visible.sync="checkDataModal" size="80%">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">查看试题</div>
        </div>
        <div style="padding: 10px 20px; height: calc(100% - 60px);">
          <div class="table">
            <Table border :columns="columns" :data="dataList"></Table>
          </div>
          <div class="page">
            <MyPage :total="total" :current="page" :pageSize="pageSize" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
          </div>
        </div>
      </el-drawer>
      <el-dialog
          :visible.sync="dataModal"
          width="70%">
        <div class="dialog-title" slot="title">
          <div class="dialog-title-text">试题详情</div>
        </div>
        <div>
          <DataInfo v-if="dataModal" :dataId="curData.data_id"></DataInfo>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '@/components/deleteModal.vue';
import { MessageBox } from "element-ui";
import Train from './train';
import Exam from "./exam.vue";
import Inter from "../interact.vue";
import DataInfo from '@/views/course/components/dataInfo';
export default {
  name: "taskView",
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 10,
      courseId: '',
      mapId: '',
      util: util,
      curData: {},
      dialogVisible: false,
      examType: '',
      deleModalStatus: false,
      modalLoading: false,
      trainShow: false,
      examShow: false,
      interactionShow: false,
      fileType:"",
      experDrawerShow:false,
      linkInfoList:[],
      experInfoList:[],
      drawerTitle:"",
      nodeData:{},
      curFileIndex:0,
      fileList:[],
      drawerShow:false,
      taskId:"",
      checkDataModal:false,
      dataList:[],
      dataModal:false,
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '所属章节',
          render:(h,params)=>{
            return h('span', params.row.node ? params.row.node.name : '');
          }
        },
        {
          title: '试题名称',
          render:(h,params)=>{
            return h('span', params.row.simpleData ? params.row.simpleData.title : '');
          }
        },
        {
          title: '更新时间',
          render:(h,params)=>{
            return h('span', util.timeFormatter(new Date(+params.row.simpleData.updated_at*1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.dataModal = true;
                  }
                }
              }, '预览'),
            ]);
          }
        },
      ],
      deleMessage:'确定要删除该周任务吗？',
      deleTit:'确定要删除该周任务吗?',
    }
  },
  components: {
    DeleModal,
    Train,
    Exam,
    Inter,
    DataInfo
  },
  created() {
    this.courseId = this.$route.query.id || '';
    this.mapId = this.$route.query.mapId || '';
    this.getList();
  },
  methods: {
    getList() {
      const { courseId } = this;
      this.api.course.courseTaskList({
        course_id: courseId
      }).then((res) => {
        res.list.forEach((item, index) => {
          if (index === 0) {
            item.show = true;
          } else {
            item.show = false;
          }
        });
        this.doneStatus = res.doneStatus || {};
        this.tableData = res.list || [];
        this.tableData.forEach((item,index)=>{
          this.recursion(item.children);
        })
      });
    },
    recursion(data){
      data.forEach((item,index)=>{
        this.$set(item,'index',index);
        if(item.children && item.children.length>0){
          this.recursion(item.children);
        }
      })
    },
    // 展开某行周任务
    expand(item) {
      item.show = !item.show;
    },
    changePage(page){
      this.page = page;
      this.getDataList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getDataList();
    },
    createTask() {
      this.$router.push({
        path: '/course/createTasks',
        query: {
          id: this.courseId,
          mapId: this.mapId,
        }
      })
    },
    // 打开周模考列表
    openWeekTestList(item){
      this.taskId=item.id;
      this.examShow = true;
    },
    // 章节转化为周课表
    toWeeklySchedules() {
      const { courseId } = this;
      MessageBox.confirm('该操作不可逆是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.course.toWeeklySchedules({
          course_id: courseId
        }).then(res => {
          console.log("自动创建周任务", res);
          this.getList();
          this.$Message.success("操作成功");
        });
      });
    },
    edit(data) {
      this.curData = data;
      this.$router.push({
        path: '/course/createTasks',
        query: {
          id: this.courseId,
          mapId: this.mapId,
          taskId: data.id,
        }
      })
    },
    createTrain(data) {
      this.curData = data;
      let params = {
        op: 'create',
        generate_type: 14,
        generate_id: this.curData.id,
        type: 0,
        generate_data_rule: 1,  //2为固定模考 12为随机模考
        from_type: 1,
        from_id: this.curData.id,

      }

      this.api.course.exercisePublish(params).then((res) => {
        this.$router.push({
          path: '/course/task/train',
          query: {
            id: res.exercise_id,
            taskId: this.curData.id,
          }
        })
      })

    },
    createExam(data) {
      this.curData = data;
      this.dialogVisible = true;
    },
    createExamType(type) {
      this.examType = type;
      let params = {
        op: 'create',
        generate_type: 1,
        generate_id: this.curData.id,
        type: 1,
        generate_data_rule: type,  //2为固定模考 12为随机模考
        from_type: 1,
        from_id: this.curData.id
      }

      this.api.course.examinePublish(params).then((res) => {
        if (type == 2) {
          this.$router.push({
            path: '/course/task/exam',
            query: {
              id: res.examine_id,
              trainId: res.train_id,
              taskId: this.curData.id,
              type: type
            }
          })
        } else {
          this.$router.push({
            path: '/course/task/randomExam',
            query: {
              id: res.examine_id,
              trainId: res.train_id,
              taskId: this.curData.id,
              type: type
            }
          });
        }
      })
    },
    dele(data) {
      let params = {
        task_id:data.id
      };
      this.api.course.taskRelationDataCount(params).then((res)=>{
        if(res.examine_count > 0 || res.interact_count > 0){
          this.deleTit = '确定要删除该周任务吗？';
          this.deleMessage = '确认删除后该周任务下的全部模考记录及互动答题记录都将被删除；请谨慎操作！';
        }
        this.curData = data;
        this.deleModalStatus = true;
      })
    },
    moveTask(item,type){
      this.api.dataset.moveTask({
        id:item.id,
        course_id:item.course_id,
        direction:type
      }).then(()=>{
        this.$Message.success("移动成功");
        this.getList();
      });
    },
    cancelDele() {
      this.deleModalStatus = false;
    },
    confirmDele() {
      let params = {
        task_id: this.curData.id,
      };
      this.modalLoading = true;
      this.api.course.courseTaskDelete(params).then(() => {
        this.modalLoading = false;
        this.$Message.success('删除成功');
        this.deleModalStatus = false;
        this.getList();
      }).catch(() => {
        this.modalLoading = false;
        this.deleModalStatus = false;
      });
    },
    // 查看
    viewData(item, type) {
      this.fileList = [];
      this.curFileIndex = 0;
      this.nodeData = item;
      if (type === "ppt") {
        this.handleFileData(item.ppt_info);
        this.drawerTitle = `查看课件-${item.node.name}`;
        this.fileType = "ppt";
        this.drawerShow = true;
      } else if (type === "video") {
        this.handleFileData(item.vedio_info);
        this.drawerTitle = `查看视频-${item.node.name}`;
        this.fileType = "video";
        this.drawerShow = true;
      } else if (type === "enchiridion") {
        this.handleFileData(item.enchiridion_info);
        this.drawerTitle = `查看实验-${item.node.name}`;
        this.experInfoList = item.experiment_info || [];
        this.linkInfoList = item.link_info || [];
        this.fileType = "enchiridion";
        this.experDrawerShow = true;
      }else if(type==="data"){
        this.getDataList(item);
      }
    },
    handleFileData(list) {
      list.forEach((item) => {
        if (!item.path.indexOf('http') == 0) {
          item.path = this.constant.URL + '/uploadfile/show/?file=' + item.path;
        }
        this.fileList.push({
          ...item
        })
      });
    },
    // 切换预览文件
    changeFileName(index) {
      this.curFileIndex = index;
    },
    // 打开实验链接
    openExperiment(item, authUrl) {
      if (authUrl) {
        this.loadingId = item.id;
        this.experLinkLoading = true;
        this.api.course.getLinkAuthUrl({
          experiment_id: item.id
        }).then(res => {
          this.experLinkLoading = false;
          window.open(res.link, "_blank");
        });
      } else {
        window.open(item.path, "_blank");
      }
    },
    getDataList(item){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        map_id:this.mapId,
        node_id:item.node_child_id
      };
      this.api.course.mapDataList(params).then((res)=>{
        this.dataList = res.list;
        this.total = Number(res.count);
      });
      this.checkDataModal=true;
    },
    openWeekInteract(data){
      this.curData = data;
      this.interactionShow = true;
    }
  }
}
</script>

<style scoped lang="scss">
.disabled{
  background-color: #eeeeee !important;
  color: #999999;
  border:none;
  &:hover{
    color: #999999;
  }
}
.table {
  margin-top: 20px;

  .btn {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.page {
  margin-top: 20px;
  text-align: right;
}

.exam-type {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .exam-type-item {
    padding: 20px;
    width: 305px;
    height: 260px;
    background: #F8F8F9;
    border: 3px solid transparent;
    border-radius: 4px;
    font-size: 14px;
    color: #666666;
    cursor: pointer;

    .exam-type-item-tit {
      margin: 10px 0 8px 0;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
  }

  .active {
    border: 3px solid #5578F6;
  }
}

.directory {
  margin-top: 20px;
  .directory-item {
    .directory-item-title {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 60px 0 30px;
      height: 60px;
      background-color: #F6F7FA;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: center;

        img {
          width: 17px;
        }
      }
    }

    .subsection-list {
      transition: .3s;
      margin:10px 20px;
      max-height: 0px;
      transition: max-height 0.5s;
      overflow: hidden;

      .directory-subsection-item {
        height: 70px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .sub-left {
          font-size: 16px;
          cursor: pointer;

          .sub-title {
            &:hover {
              color: #4E83FF;
            }
          }

          .count {
            margin-top: 6px;
            color: #666666;
            font-size: 14px;
          }
        }

        .sub-right {
          display: flex;
          align-items: center;

          .sub-right-item {
            margin-left: 10px;
            display: flex;
            align-items: center;
            padding: 3px 16px;
            border-radius: 30px;
            font-size: 14px;
            color:#333333;
            font-weight: bold;
            text-align: center;
            line-height: 30px;
            word-break: keep-all;
            cursor: pointer;
            background-color: #EEEEEE;
            &:hover{
              background-color: #5578F6;
              color:#FFFFFF;
              .icon{
                filter:brightness(100);
              }
              >span{
                color:white;
              }
            }
            .icon{
              width: 16px;
              height:16px;
              display:block;
              margin-right: 3px;
            }
            >span{
              color: #666;
              font-weight: normal;
            }
          }
        }
      }
    }
  }


}
.experiment {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: calc(100% - 20px);

  .experiment-pdf {
    margin-right: 20px;
    height: 100%;

    .experiment-pdf-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  >div {
    flex: 1;
    width: 0;

  }

  .experiment-r {
    .experiment-r-iframe {
      width: 100%;
      height: calc(100% - 32px);
    }
  }
}
.name-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >div {
    margin: 0 10px 10px 0;
    padding: 0 10px;
    border: 1px solid #dcdee2;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    border: 1px solid #2d8cf0;
    color: #2d8cf0;
  }
}
</style>
