<template>
  <el-drawer :visible.sync="drawerShow" size="80%" @close="$emit('close')">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">周模考列表</div>
    </div>
    <div style="padding: 20px;">
      <div class="table">
        <el-table
            :data="tableData"
            border>
          <el-table-column
            prop="id"
            label="ID"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              label="模考名称"
              show-overflow-tooltip
              width="170">
          </el-table-column>
          <el-table-column
              prop="address"
              label="所属课程任务"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.generate_task && scope.row.generate_task.name}}
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              label="试卷类型">
            <template slot-scope="scope">
              {{types[scope.row.generate_data_rule]}}
            </template>
          </el-table-column>
          <el-table-column
              prop="timelong"
              label="模考时长(分钟)">
          </el-table-column>
          <el-table-column
              prop="pass_score"
              label="及格分数">
          </el-table-column>
          <el-table-column
              prop="assignNames"
              label="模考分配">
          </el-table-column>
          <el-table-column
              prop="user_count"
              label="应考人数">
          </el-table-column>
          <el-table-column
              prop="join_user_count"
              label="提交人数">
          </el-table-column>
          <el-table-column
              prop="address"
              show-overflow-tooltip
              label="可进入时间">
            <template slot-scope="scope">
              {{util.timeFormatter(new Date(+scope.row.start_time*1000), 'yyyy-MM-dd hh:mm')}}
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              label="状态">
            <template slot-scope="scope">
              {{status[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              label="操作"
              width="200px"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="small" class="mb10 btnS" @click="edit(scope.row)"  v-if="scope.row.status == 0 || scope.row.status == 1" >编辑</el-button>
              <el-button type="primary" size="small" class="mb10 btnS" v-if="scope.row.status != 0"  @click="analysis(scope.row)">考试结果和分析</el-button>
              <el-button type="primary" size="small" class="mb10 btnS" @click="check(scope.row)" v-if="scope.row.status == 2 || scope.row.status == 3">查看</el-button>
              <el-button type="primary" size="small" class="mb10 btnS" v-if="scope.row.status != 0 && scope.row.generate_data_rule == 12" @click="preview(scope.row)" >预览试卷</el-button>
              <el-button type="danger" size="small" class="mb10 btnS" @click="dele(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
      <DeleModal :status="deleModalStatus" txt="确认删除吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
    </div>
  </el-drawer>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '@/components/deleteModal.vue';
export default {
  name: "taskView",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    courseId:{
      type:String,
      required:true
    },
    mapId:{
      type:String,
      requried:true
    },
    taskId:{
      type:String,
      required:true
    }
  },
  data(){
    return{
      drawerShow:false,
      tableData:[],
      total:0,
      page:1,
      pageSize:10,
      util:util,
      types:{},
      status:{},
      curData:{},
      deleModalStatus:false,
      modalLoading:false,
    }
  },
  components:{
    DeleModal
  },
  created(){
    // this.courseId = this.$route.query.id || '';
    // this.mapId = this.$route.query.mapId || '';
    // this.getList();
  },
  methods:{
    getList(){
      let params = {
        course_id:this.courseId,
        page:this.page,
        pageSize:this.pageSize,
        task_id:this.taskId
      };
      this.api.course.examineList(params).then((res)=>{
        if(this.page > 1 && !res.list.length){
          this.getList();
        }
        this.tableData = res.list;

        this.tableData.forEach((item)=>{
          let names = item.assign_value_list.map((sItem)=>{
            return sItem.name || sItem.nickname;
          }).join(',');
          this.$set(item,'assignNames',names);
        })

        this.total = Number(res.count);
        this.types = res.types;
        this.status = res.status;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    edit(data){

      if(data.generate_data_rule == 2){
        this.$router.push({
          path:'/course/task/exam',
          query:{
            id:data.id,
            taskId:data.generate_task.id,
            type:data.generate_data_rule
          }
        })
      }else{
        this.$router.push({
          path:'/course/task/randomExam',
          query:{
            id:data.id,
            taskId:data.generate_task.id,
            type:data.generate_data_rule
          }
        })
      }
    },
    check(data){
      if(data.generate_data_rule == 2){
        this.$router.push({
          path:'/course/task/exam',
          query:{
            id:data.id,
            taskId:data.generate_task.id,
            type:data.generate_data_rule,
            isEdit:false,
          }
        })
      }else{
        this.$router.push({
          path:'/course/task/randomExam',
          query:{
            id:data.id,
            taskId:data.generate_task.id,
            type:data.generate_data_rule,
            isEdit:false,
          }
        })
      }
    },
    dele(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let params = {
        id:this.curData.id,
      };
      this.modalLoading = true;
      this.api.course.examineDel(params).then((res)=>{
        this.modalLoading = false;
        this.$Message.success('删除成功');
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      })
    },
    create(){
      this.$emit('changeTab')
    },
    preview(data){
      this.curData = data;



      const routeUrl = this.$router.resolve({
        path:'/course/task/exam/preview',
        query:{
          id:data.id,
        }
      })
      window.open(routeUrl.href, "_blank");

    },
    analysis(data){
      this.$router.push({
        path:'/course/analysis/paperAnalysis',
        query:{
          id:data.id
        }
      })
    }
  },
  watch:{
    show(newVal){
      if(newVal){
        this.getList();
      }
      this.drawerShow=newVal;
    }
  }
}
</script>

<style scoped lang="scss">
.exam{
  padding: 20px;
  .exam-main{
    background-color: white;
    padding: 20px;
    border-radius: 4px;
  }
}
.table{
  margin-top: 20px;
}
.page{
  margin-top: 20px;
  text-align: right;
}
.btnS{
  margin-left: 0;
  margin-right: 10px;
}
</style>
