<template>
  <el-drawer :visible.sync="drawerShow" :size="1200" @close="$emit('close')">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">周练习列表</div>
    </div>
    <div style="padding: 20px;">
      <div class="practice">
        <!-- <el-button type="primary" @click="create">去发布练习 ></el-button> -->
        <div class="practice-main">
          <div class="table">
            <el-table
                :data="tableData"
                border
                size="small"
                style="width: 100%">
              <el-table-column
                  prop="id"
                  label="ID"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="练习名称"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="所属课程任务">
                <template slot-scope="scope">
                  {{scope.row.generate_task && scope.row.generate_task.name}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="user_count"
                  label="应练人数">
              </el-table-column>
              <el-table-column
                  prop="join_user_count"
                  label="实练人数">
              </el-table-column>
              <el-table-column
                  prop="assignNames"
                  label="练习分配">
              </el-table-column>
              <el-table-column
                  label="创建时间">
                <template slot-scope="scope">
                  {{util.timeFormatter(new Date(+scope.row.created_at*1000), 'yyyy-MM-dd hh:mm')}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="操作"
                  width="150"
              >
                <template slot-scope="scope">
      <!--            <el-button type="primary" size="small">练习分析</el-button>-->
                  <el-button type="primary" size="small" v-if="scope.row.status == 0" @click="edit(scope.row)">编辑</el-button>
                  <el-button type="danger" size="small" @click="dele(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="page">
            <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
          </div>
          <DeleModal :status="deleModalStatus" txt="确认删除吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '@/components/deleteModal.vue';
export default {
  name: "taskView",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    courseId:{
      type:String,
      required:true
    }
  },
  data(){
    return{
      drawerShow:false,
      util:util,
      tableData:[],
      total:0,
      page:1,
      pageSize:10,
      deleModalStatus:false,
      modalLoading:false,
    }
  },
  components:{
    DeleModal
  },
  created(){
    // this.courseId = this.$route.query.id || '';
    // this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        course_id:this.courseId,
      }
      this.api.course.exerciseList(params).then((res)=>{
        if(this.page > 1 && !res.list.length){
          this.getList();
        }
        this.tableData = res.list;
        this.tableData.forEach((item)=>{
          let names = item.assign_value_list.map((sItem)=>{
            return sItem.name || sItem.nickname ;
          }).join(',');
          this.$set(item,'assignNames',names);
        })
        this.total = Number(res.count);
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    dele(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let params = {
        id:this.curData.id,
      };
      this.modalLoading = true;
      this.api.course.exerciseDel(params).then(()=>{
        this.modalLoading = false;
        this.$Message.success('删除成功');
        this.deleModalStatus = false;
        this.getList();
      }).catch(()=>{
        this.modalLoading = false;
      })
    },
    edit(data){
      this.$router.push({
        path:'/course/task/train',
        query:{
          id:data.id,
          taskId:data.generate_task.id,
        }
      })
    },
    create(){
      this.$emit('changeTab')
    }
  },
  watch:{
    show(newVal){
      if(newVal){
        this.getList();
      }
      this.drawerShow=newVal;
    }
  }
}
</script>

<style scoped lang="scss">
.table{
  margin-top: 20px;
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
