<template>
  <el-drawer :visible.sync="drawer" title="文件预览" :size="1000" @closed="closed()">
    <iframe class="preivew-container" v-if="previewType === 'office'" :src="previewPath" frameborder="0"></iframe>
    <img class="preivew-container" v-else-if="previewType === 'img'" :src="previewPath" alt="无法预览">
    <video class="preivew-container" v-else-if="previewType === 'video'" :src="previewPath" controls muted autoplay></video>
    <embed class="preivew-container" v-else-if="previewType === 'pdf'" :src="previewPath" type="application/pdf">
    <div v-else>此文件暂不支持本地预览，已为您打开浏览器或者下载预览</div>
  </el-drawer>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  filePath: {
    type: String,
    required: true,
    default: ""
  }
});
const emit = defineEmits(["close"]);

// 微软文件常见的扩展名
const officeArr = [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx"];
// 图片文件常见的扩展名
const imgArr = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".webp", ".svg"];
// 视频文件常见的扩展名
const videoArr = [".mp4", ".webm", ".ogv"];


const previewType = ref("");
const previewPath = ref("");


const drawer = ref(false);
watch(() => props.show, (newV) => {
  drawer.value = newV;
  if (newV) {
    const extension = props.filePath.slice(props.filePath.lastIndexOf("."));
    if (officeArr.includes(extension)) {
      previewType.value = "office";
    }
    if (imgArr.includes(extension)) {
      previewType.value = "img";
    }
    if (videoArr.includes(extension)) {
      previewType.value = "video";
    }
    if (extension === ".pdf") {
      previewType.value = "pdf";
    }

    switch (previewType.value) {
      // 如果是office文件
      case "office":
        previewPath.value = "https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(props.filePath);
        break;
      // 如果不是已知文件或者没有后缀
      case "":
        window.open(props.filePath, "_blank");
        break;
      default:
        previewPath.value = props.filePath;
        break;
    }
  }
});
function closed() {
  previewPath.value = "";
  previewType.value = "";
  emit("close");
}

</script>

<style lang="scss" scoped>
.preivew-container {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>