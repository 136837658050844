<template>
  <div class="study table-border">
    <div class="top-tip mt20">
      提示：数据统计存在延迟， 延迟时间约1小时左右，请耐心等待！
    </div>
    <div class="tabs">
      <div class="tab-item" :class="item.id === tabActive ? 'tab-activc' : ''" v-for="item in tabList" :key="item.id"
        @click="changeAnalysis(item.id)">{{ item.name }}</div>
    </div>
    <div class="search-box">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="筛选周课表:">
          <el-select v-model="searchForm.task_id" placeholder="请选择周任务筛选" size="small" @change="changeTask">
            <el-option v-for="item in taskList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="筛选班级:" v-if="!groupId">
          <el-select v-model="searchForm.group_id" placeholder="请选择周任务筛选" size="small" @change="changeTask">
            <el-option v-for="item in classList" :key="item.group_id" :label="item.group.name" :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="tabActive === '1'">
      <div class="title">章节学习分析</div>
      <el-table style="margin-bottom: 20px;" :style="{height:item.isFold ? '40px' : 'auto'}" v-for="(item) in tableData" :key="item.id+new Date().getTime()" :data="item.children"
                default-expand-all
                row-key="id"
                :border="false"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                size="small" >
        <el-table-column :label="item.node.name" prop="">
          <template slot="header">
            <div class="table-title" @click="foldTable(item)">
              <div>{{ item.node.name }}</div>
              <div :class="item.isFold?'el-icon-caret-bottom':'el-icon-caret-top'" ></div>
            </div>
          </template>
          <el-table-column label="节" prop="node.name"></el-table-column>
          <el-table-column label="课件查看人数">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_ppt_done_user_count || '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="课件未查看人数">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_ppt_undone_user_count || '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="视频查看人数">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_video_done_user_count || '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="视频未看人数">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_video_undone_user_count || '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="试题练习人数">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_train_user_count || '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="试题平均正确率">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_avg_right_rate ? row.stat.node_avg_right_rate + '%' : '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="试题平均练习时长">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_user_use_time ? util.secondsToMS(row.stat.node_user_use_time / row.stat.user_count) :
                  '-') :'' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="章节完成率">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.node_user_finish_rate ? row.stat.node_user_finish_rate + '%' : '-') : '' }}</div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <div class="title">每日学习人数</div>
      <div class="empty" v-if="chartData.length === 0">
        <img src="../../../assets/images/new_icon/empty.png" alt="">
        <div>暂无数据</div>
      </div>
      <div ref="studyChart" v-else class="studyChart"></div>
      <!-- <div class="title">整体知识点掌握分析</div>
      <div class="schedule">
        <div class="schedule-item" v-for="item in 10" :key="item">
          <div class="schedule-title">云计算</div>
          <div class="schedule-main">
            <el-progress :percentage="50" :stroke-width="12"></el-progress>
            <div>掌握程度：80%</div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-show="tabActive === '2'">
      <div class="search-box" style="margin-bottom: 20px;">
        <Input v-model="stuSearchForm.keyword" style="width: 300px;" placeholder="搜索学生姓名、ID" clearable search
          :enter-button="true" @on-enter="getStuList()" @on-search="getStuList()" />
      </div>
      <el-table :data="stuTabelData" border size="small">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="学生姓名" prop="realname">
          <template v-slot="{ row }">{{ row.realname || row.nickname }}</template>
        </el-table-column>
        <el-table-column label="章节完成率">
          <template v-slot="{ row }">
            {{ row.stat.finish_rate ? row.stat.finish_rate + "%" : "-" }}
          </template>
        </el-table-column>
        <el-table-column label="刷题数量" prop="stat.train_count">
          <template v-slot="{ row }">
            {{ row.stat.train_count ? row.stat.train_count : "-" }}
          </template>
        </el-table-column>
        <el-table-column label="刷题正确率">
          <template v-slot="{ row }">
            {{ !!+row.stat.right_count ? (row.stat.right_count / row.stat.train_count * 100).toFixed(2) + "%" : "-" }}
          </template>
        </el-table-column>
        <el-table-column label="刷题错误率">
          <template v-slot="{ row }">
            {{ !!+row.stat.train_count ? ((row.stat.train_count - row.stat.right_count) / row.stat.train_count *
              100).toFixed(2) + "%" :
              "-" }}
          </template>
        </el-table-column>
        <el-table-column label="平均答题时长">
          <template v-slot="{ row }">
            {{ !!+row.stat.use_time ? util.secondsToMS(row.stat.use_time / row.stat.train_count) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <el-button type="primary" size="small" @click="preview(row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagin style="text-align: center; margin-top: 10px;" :pagination="pagination" @currentChange="currentChange"
        @sizeChange="sizeChange" />
    </div>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
import Pagin from "@/components/myPagin.vue";
export default {
  name: "StuAnalysis",
  props: {
    courseId: {
      type: String,
      required: true
    }
  },
  components: {
    Pagin
  },
  created() {
    this.groupId = this.$route.query.groupId || '';
    if(this.groupId){
      this.searchForm.group_id = this.groupId;
    }
    this.getTaskList();
    this.getClassList();
  },
  data() {
    return {
      util,
      tabList: [
        {
          id: "1",
          name: "整体分析"
        },
        {
          id: "2",
          name: "学生分析"
        }
      ],
      tabActive: "1",

      taskList: [],
      searchForm: {
        task_id: "-1",
        group_id: ""
      },
      // 班级列表
      classList: [],
      // 章节学习分析
      tableData: [],
      // echart数据
      chartData: [],
      // 学生分析表格数据
      stuTabelData: [],
      stuSearchForm: {
        keyword: ""
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      myChart: null,
      groupId:'',
    }
  },
  methods: {
    // 切换分析
    changeAnalysis(id) {
      this.tabActive = id;
      if (this.tabActive == '1') {
        this.getTableList();
      } else {
        this.getStuList();
      }
    },
    // 获取周任务列表
    getTaskList() {
      const { courseId } = this;
      this.api.course.courseTaskList({
        course_id: courseId
      }).then(res => {
        res.list.unshift({
          name: "全部",
          id: "-1"
        });
        this.taskList = res.list || [];
        console.log("任务列表", res.list);
      });
    },
    changeTask() {
      if (this.tabActive == '1') {
        this.getTableList();
      } else {
        this.getStuList();
      }
    },
    // 获取班级列表
    getClassList() {
      const { searchForm, courseId } = this;
      this.api.course.courseGroupList({
        course_id: courseId
      }).then(res => {
        this.classList = res.list || [];
        searchForm.group_id = this.classList[0] ? this.classList[0].group_id : "";
        this.getTableList();
        this.getStuList();
      });
    },
    // 获取整体分析的表格和图标数据
    getTableList() {
      const { searchForm, courseId } = this;
      this.api.course.courseStatistics({
        ...searchForm,
        course_id: courseId
      }).then(res => {
        console.log("整体分析数据", res);
        res.map_node_stat_tree?.forEach((item, index) => {
          if (index === 0) {
            // item.isFold = false;
            this.$set(item,"isFold",false);
          } else {
            // item.isFold = true;
            this.$set(item,"isFold",true);
          }
        });
        this.tableData = res.map_node_stat_tree || [];
        this.chartData = res.stat_user_day_stat || [];
        this.setStudyChart();
      });
    },
    // 折叠
    foldTable(item) {
      console.log(item);
      item.isFold = !item.isFold;
    },
    classChange(id) {
      const { searchForm, pagination } = this;
      searchForm.group_id = id;
      if (this.tabActive == '1') {
        this.getTableList();
      } else {
        pagination.page = 1;
        this.getStuList();
      }
    },
    setStudyChart() {
      const { tabActive, chartData } = this;
      if(chartData.length===0){
        this?.myChart?.dispose();
        this.myChart=null;
        return;
      }
      if (tabActive !== "1") return;
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = this.$echarts.init(this.$refs.studyChart);
        }
        const option = {
          // 提示
          tooltip: {
            trigger: 'axis'
          },
          // 布局
          grid: {
            left: '20px',
            right: '20px',
            bottom: '20px',
            top: "20px",
            containLabel: true
          },
          // x轴
          xAxis: {
            type: 'category',
            data: chartData.reduce((pre, cur) => {
              pre.push(cur.date);
              return pre;
            }, [])
          },
          // y轴
          yAxis: {
            type: 'value',
            minInterval: 1
          },
          // 数据
          series: [
            {
              name: '学习人数',
              type: 'line',
              stack: 'Total',
              data: chartData.reduce((pre, cur) => {
                pre.push(cur.user_count);
                return pre;
              }, []),
              smooth: true
            },
          ]
        }
        this.myChart.setOption(option);
      });
    },
    // 获取学生列表
    getStuList() {
      const { searchForm, courseId, pagination, stuSearchForm } = this;
      this.api.course.stuCourseStatistics({
        ...searchForm,
        course_id: courseId,
        ...pagination,
        ...stuSearchForm
      }).then(res => {
        pagination.total = Number(res.count);
        this.stuTabelData = res.user_list || [];
        console.log("学生分析数据", res);
      });
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getStuList();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getStuList();
    },
    preview(row) {
      const { courseId, searchForm } = this;
      this.$router.push({
        path: "/teacher/course/analysisStudents",
        query: {
          courseId: courseId,
          taskId: searchForm.task_id,
          stuDetail: JSON.stringify({
            realname: row.realname,
            ...row.stat
          })
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.study {
  .tabs {
    margin-top: 20px;
    display: flex;

    .tab-item {
      font-size: 16px;
      margin-right: 10px;
      padding: 5px 10px;
      border: 1px solid #E3E3E3;
      border-radius: 4px;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 5px;
      color: #666666;
    }

    .tab-activc {
      background-color: #4A79FF;
      color: white;
      border: none;
    }
  }

  .search-box {
    margin-top: 20px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;

    img {
      height: 180px;
    }
  }

  .title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    position: relative;
    padding-left: 15px;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 14px;
      top: 3px;
      left: 0;
      background: #5578F6;
    }
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .el-icon-caret-bottom {
      font-size: 20px;
      cursor: pointer;
    }
    .el-icon-caret-top{
      font-size: 20px;
      cursor: pointer;
    }
  }

  .studyChart {
    height: 500px;
    width: 100%;
  }

  .schedule {
    padding: 20px;

    .schedule-item {
      margin-bottom: 12px;

      .schedule-title {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .schedule-main {
        display: flex;

        .el-progress {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
<style>
.study.table-border .el-table--border .el-table__cell{
  border-right: none;
}
</style>
