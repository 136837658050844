<template>
  <div class="source">
    <!--  <MyBreadcrumb></MyBreadcrumb>-->
    <div class="source-nr">
      <!--    <div class="name" @click="goBack">-->
      <!--      <img src="../../assets/images/new_icon/back.png"  width="10" height="17" />-->
      <!--      <p>{{ info.name }}</p>-->
      <!--    </div>-->
      <div class="source-top">
        <div class="source-list-tab">
          <div class="source-list-tab-item" @click="goAllSource">
            <p>课程全部教学资源</p>
            <img src="../../assets/images/new_icon/you_gray_icon.png" width="8" height="13" />
          </div>
          <div class="source-list-tab-item" v-for="(item, index) in tabList" :key="item.id" @click="goTab(item, index)">
            <img src="../../assets/images/new_icon/wenjianjia.png" width="22" height="19" />
            <p>{{ item.name }}</p>
            <img src="../../assets/images/new_icon/you_gray_icon.png" width="8" height="13"
              v-if="index < tabList.length - 1" />
          </div>
        </div>
        <div class="top-btns">
          <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
            type: 3
          }" :headers="{
            'access-token': Cookies.get(constant.tokenName)
          }" :on-success="uploadFileSuccess" :show-upload-list="false" multiple :before-upload="beforeUpload"
            class="mr10">
            <Button icon="ios-cloud-upload-outline" type="primary" :loading="fileLoading">上传文件</Button>
            <!--          <span style="padding-left: 20px">{{ addFileName ? addFileName : '未选择文件' }}</span>-->
          </Upload>

          <Button @click="addFold">创建文件夹</Button>
        </div>
      </div>

      <Table border :columns="columns" :loading="loading" :data="dataList"></Table>
      <div class="page">
        <Page :total="total" :page-size-opts="[10, 20, 50, 100]" @on-change="changePage"
          @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
    </div>
    <!-- <Modal
      v-model="modal"
      title="创建/编辑文件夹"
  >
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100" @submit.native.prevent>
      <FormItem label="文件夹名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入文件夹名称" ></Input>

      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="primary"  @click="handleSubmit">确定</Button>
      <Button @click="cancelModal">取消</Button>
    </div>
  </Modal> -->

    <el-drawer :size="800" :visible.sync="modal">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">创建/编辑文件夹</div>
        <div class="drawer-title-btns">
          <Button type="primary" @click="handleSubmit" style="margin-right: 10px;">确定</Button>
          <Button @click="cancelModal">取消</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="100" @submit.native.prevent>
          <FormItem label="文件夹名称" prop="name">
            <Input v-model="formValidate.name" placeholder="请输入文件夹名称"></Input>
          </FormItem>
        </Form>
      </div>
    </el-drawer>

    <FilePreview :show="previewDrawer" :filePath="filePath" @close="previewClose()"/>

    <DeleModal :status="deleModalStatus" txt="确定删除吗" :loading="modalLoading" @cancel="cancelDele"
      @confirm="confirmDele"></DeleModal>

  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Cookies from "js-cookie";
import FileSaver from "file-saver";
import DeleModal from '../../components/deleteModal.vue';
import FilePreview from "@/components/filePreview.vue";
export default {
  name: "sourceManage",
  data() {
    return {
      Cookies: Cookies,
      tabList: [],
      curData: {},
      columns: [
        {
          title: '文件名称',
          key: 'name',
          render: (h, params) => {
            return h('div', {
              style: {
                'display': 'flex',
                'justifyContent': 'flex-start',
                'alignCenter': 'center',
                'cursor': 'pointer'
              },
              on: {
                click: () => {
                  this.goSourceInfo(params.row)
                }
              }
            }, [
              h('img', {
                attrs: {
                  src: params.row.is_dir == '1' ? require('../../assets/images/new_icon/wenjianjia.png') : require('../../assets/images/new_icon/wenjian.png'),
                  width: params.row.is_dir == '1' ? '22' : '16'
                },
                style: {
                  'marginRight': '5px',
                }
              }),
              h('span', params.row.name)
            ]);
          }
        },
        {
          title: '大小',
          key: 'size',
          render: (h, params) => {
            return h('span', params.row.size ? params.row.size + 'M' : '-');
          }
        },
        {
          title: '格式',
          key: 'file_extension',
          render: (h, params) => {
            return h('span', params.row.is_dir == '1' ? '文件夹' : params.row.file_extension);
          }
        },
        {
          title: '上传日期',
          key: 'id',
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.created_at * 1000),
              'yyyy-MM-dd hh:mm'
            )
            );
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              params.row.is_dir == '1' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.editFold(params.row)
                  }
                }
              }, '编辑') : '',
              params.row.is_dir != '1' && h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.downLoad(params.row.path)
                  }
                }
              }, '下载'),

              params.row.is_dir != '1' && h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.check(params.row.path);
                  }
                }
              }, '预览'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curListData = params.row;
                    this.deleteSource();
                  }
                }
              }, '删除'),
            ]);
          }
        },
      ],
      previewDrawer: false,
      filePath: "",
      dataList: [],
      total: 0,
      page: 1,
      pageSize: 10,
      loading: false,
      courseId: '',
      info: {},
      formValidate: {
        name: '',
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入文件夹名称', trigger: 'blur' }
        ],
      },
      modal: false,
      fileLoading: false,
      addFile: [],
      curListData: {},
      deleModalStatus: false,
      modalLoading: false,
    }
  },
  components: { DeleModal,FilePreview },
  created() {
    this.courseId = this.$route.query.id || '';
    this.getList();
    this.getDetail();
  },
  methods: {
    getDetail() {
      let params = {
        id: this.courseId
      };
      this.api.course.courseDetail(params).then((res) => {
        this.info = res.info;
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    goAllSource() {
      this.curData = {};
      this.tabList = [];
      this.getList();
    },
    goTab(data, index) {
      this.curData = data;
      this.tabList = this.tabList.slice(0, index + 1);
      this.getList();
    },
    getList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        course_id: this.courseId,
        user_id: '-1',
      };
      if (this.curData.id) {
        params.parent_id = this.curData.id;
      }
      this.loading = true;
      this.api.course.teachingSourceList(params).then((res) => {
        this.loading = false;
        this.dataList = res.list;
        console.log(this.dataList, "-=-=-=-=");
        this.total = Number(res.count);
      }).catch(() => {
        this.loading = false;
      })
    },
    changePage(page) {
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    handleSubmit() {
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          if (this.curListData.id) {
            let params = {
              name: this.formValidate.name,
              source_id: this.curListData.id
            };
            this.api.course.teachingSourceUpdateFolder(params).then(() => {
              this.$Message.success('操作成功');
              this.modal = false;
              this.getList();
            })
          } else {
            let params = {
              course_id: this.courseId,
              name: this.formValidate.name,
              parent_id: this.curData.id,
            }
            this.api.course.teachingSourceCreateFolder(params).then(() => {
              this.$Message.success('操作成功');
              this.modal = false;
              this.page = 1;
              this.getList();
            })
          }
        }
      })
    },
    cancelModal() {
      this.modal = false;
    },
    addFold() {
      this.modal = true;
      this.formValidate.name = '';
      this.curListData = {};
    },
    uploadFileSuccess(response) {
      this.fileLoading = false;

      this.addFile.push({
        name: response.data?.info?.name,
        id: response.data?.info?.id,
        path: response.data?.info?.upload_path
      });
      let params = {
        course_id: this.courseId,
        uploadfile_ids: response.data.info.id,
        parent_id: this.tabList.length ? this.tabList[this.tabList.length - 1].id : '',
      }
      this.api.course.teachingSourceBatch(params).then(() => {
        // this.$Message.success('操作成功');
        this.getList();
      })
      // if(this.addFile.length == fileList.length){
      //   let uploadFileIds = this.addFile.filter((item)=>{
      //     return !item.from;
      //   }).map((item)=>{
      //     return item.id ;
      //   }).join(',')
      //   if(uploadFileIds){
      //     let params = {
      //       course_id:this.courseId,
      //       uploadfile_ids:uploadFileIds,
      //       parent_id:this.tabList.length ? this.tabList[this.tabList.length - 1].id : '',
      //     }
      //     this.api.course.teachingSourceBatch(params).then((res)=>{
      //       this.$Message.success('操作成功');
      //       this.getList();
      //     })
      //   }
      // }
    },
    beforeUpload() {
      this.fileLoading = true;
      // this.addFile = [];
    },
    check(path = "") {
      console.log(path);
      this.filePath=path;
      this.previewDrawer=true;
    },
    previewClose(){
      this.filePath="";
      this.previewDrawer=false;
    },
    downLoad(data) {
      // util.downloadFile(this,data);
      let name = data.split('/');
      name = name[name.length - 1].split('.');
      name = name[0] + '.' + name[1];
      FileSaver.saveAs(data, name);
    },
    editFold(data) {
      this.curListData = data;
      this.formValidate.name = this.curListData.name;
      this.modal = true;
    },
    goSourceInfo(data) {

      if (data.is_dir == '1') {
        this.curData = data;
        this.tabList.push({
          id: this.curData.id,
          name: this.curData.name
        })
        this.getList();
      }

    },
    deleteSource() {
      this.deleModalStatus = true;
    },
    cancelDele() {
      this.deleModalStatus = false;
    },
    confirmDele() {
      let data = {
        source_id: this.curListData.id,
      }
      this.modalLoading = true;
      this.api.course.teachingSourceDel(data).then(() => {
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch(() => {
        this.modalLoading = false;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.source {
  //margin: 20px;
  font-size: 14px;

  .source-nr {
    //padding: 20px;
    background-color: #FFFFFF;

    .name {
      padding-bottom: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      border-bottom: 2px solid #EEEEEE;
      cursor: pointer;

      >img {
        margin-right: 10px;
      }
    }

    .source-top {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .source-list-tab {
      //margin-top: 30px;
      //margin-bottom: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .source-list-tab-item {
        margin-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        >p {
          margin: 0 10px;
        }
      }
    }

    .page {
      margin-top: 20px;
      text-align: right;
    }
  }
}

::v-deep .el-drawer {
  .el-drawer__header {
    margin-bottom: 0;
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .drawer-title-text {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    .drawer-title-btns {
      padding-right: 40px;
    }
  }
}
</style>
