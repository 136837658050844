<template>
<div class="course-detail">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="course-detail-nr">
<!--    <div class="course-detail-nr-top">-->
<!--      <p class="tit">{{ info.name }}</p>-->

<!--    </div>-->
    <div class="chapters-detail">
      <div class="chapters-detail-tit">
        <p>{{ info.name }}</p>

      </div>
      <div class="chapters-detail-desc">{{info.content}}</div>
      <div class="chapters-detail-nums">
        <div class="chapters-detail-nums-val">
          <div class="chapters-detail-num">共{{info.lesson_count}}课时</div>
          <div class="chapters-detail-num">{{info.lesson_week_count}}周课</div>
          <div class="chapters-detail-num" v-if="total_ppt_count > 0">{{total_ppt_count}}个课件</div>
          <div class="chapters-detail-num" v-if="total_video_count > 0">{{total_video_count}}个视频</div>
          <div class="chapters-detail-num" v-if="Number(total_experiment_count) + Number(total_source_link_count) > 0">{{Number(total_experiment_count) + Number(total_source_link_count)}}个实验</div>
          <div class="chapters-detail-num" v-if="total_enchiridion_count > 0">{{total_enchiridion_count}}个实验手册</div>
          <div class="chapters-detail-num" v-if="total_quantity > 0">{{total_quantity}}道题</div>
        </div>
        <div class="chapters-detail-nums-btns">
          <div class="check-source-btn" v-if="routerPath == '/course/detail'" @click="edit">编辑</div>
          <div class="check-source-btn" @click="goMap" v-if="routerPath == '/course/detail' && isPrivate">设置图谱</div>
          <div class="check-source-btn live-btn" v-if="userSetting.open_webrtc==='1'" @click="showLiveDrawer()">开启课堂直播</div>
          <div class="check-source-btn" v-if="routerPath == '/teacher/course/detail'" @click="goMaterialCreate(info,1,2)">创建考试</div>
        </div>
      </div>

    </div>
    <div class="tab">
      <p :class="curTab.id == item.id ? 'active' : ''" v-for="(item,index) in tabList" :key="index" @click="changeTab(item)">{{ item.name }}</p>
    </div>
    <div v-if="info.id">
      <component :is="curTab.compontName" :tree="mapTree" @changeTab="changeTab" :courseId="dataId" :mapId="info.map_id"></component>

    </div>
  </div>

  <!-- 编辑抽屉 -->
  <el-drawer :visible.sync="drawerShow" :size="1200" @close="drwaerColse()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ drawerTitle }}</div>
        <div class="drawer-title-btns">
          <Button class="mr10" size="large" @click="drwaerColse()">取消</Button>
          <Button type="primary" size="large" @click="confirm">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <div class="course-create-nr">
          <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="110px">
            <div class="distinguish" v-if="!isPrivate">
              <div class="distinguish-color"></div>
              <div class="distinguish-text">可编辑</div>
            </div>
            <div class="distinguish" v-if="!isPrivate">
              <div class="distinguish-color back-red"></div>
              <div class="distinguish-text">不可编辑</div>
            </div>
            <el-form-item label="方向" prop="category_id">
              <el-select v-model="formValidate.category_id" v-if="isPrivate" :disabled="!isPrivate || !allowEditCategory"
                placeholder="请选择方向" class="w300">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-input v-else v-model="categoryName" disabled size="large" placeholder="请输入名称" class="w300"></el-input>
              <span v-if="isPrivate" style="padding-left: 20px">选择课程所属分类，还没有分类？<a @click="goCategory"
                  style="color: #4a79ff">点击去创建</a></span>
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input v-model="formValidate.name" maxlength="20" :disabled="!isPrivate" size="large" placeholder="请输入名称"
                class="w300"></el-input>
            </el-form-item>
            <el-form-item label="等级" prop="lesson_level">
              <el-select v-model="formValidate.lesson_level" :disabled="!isPrivate" placeholder="请选择等级" class="w300">
                <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课时数" prop="lesson_count">
              <el-input-number :disabled="!isPrivate" v-model="formValidate.lesson_count" size="large"
                placeholder="请输入课时数" style="width: 180px"></el-input-number>
            </el-form-item>
<!--            <el-form-item label="周课数">-->
<!--              <el-input-number type="number" :disabled="!isPrivate" v-model="formValidate.lesson_week_count" size="large"-->
<!--                placeholder="请输入周课数" style="width: 180px"></el-input-number>-->
<!--            </el-form-item>-->
            <el-form-item style="width: 50%" label="课程描述" prop="content">
              <el-input type="textarea" :disabled="!isPrivate" v-model="formValidate.content" :rows="5" style="width: 70%"
                size="large" placeholder="请输入课程描述"></el-input>
            </el-form-item>
            <el-form-item label="封面图" prop="uploadImg" class="w300">
              <el-upload :action="constant.URL + '/uploadfile/upload'" :data="{
                type: 3,
              }" accept=".jpg,.png,.jpeg,.gif" :headers="{
  'access-token': Cookies.get(constant.tokenName),
}" :on-success="uploadFileSuccess" :show-file-list="false" :disabled="!isPrivate">
                <div class="upload-btn" v-if="!formValidate.uploadImg">
                  <Icon type="ios-add" :size="50" />
                </div>
                <img :src="formValidate.uploadImg" width="120" v-else />
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>

  <!-- 创建直播 -->
  <el-drawer :visible.sync='liveDrawer' :size="800">
    <div class='drawer-title' slot='title'>
      <div class='drawer-title-text'>开启课堂直播</div>
      <div class='drawer-title-btns'></div>
    </div>
    <div style='padding:20px;'>
      <el-form ref="liveform" :model="liveForm" :rules="liveRules" label-width="100px">
        <el-form-item label="上课标题" prop="name">
          <el-input v-model="liveForm.name" type="textarea" size="small"></el-input>
        </el-form-item>
        <!-- <el-form-item label="直播班级" prop="className">
          <el-input v-model="liveForm.name" type="textarea" size="small"></el-input>
        </el-form-item> -->
        <el-form-item label="">
          <el-button size="small" type="warning" @click="createLiveRoom()">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>

</div>
</template>

<script>
// <<<<<<< HEAD
// import CourseInfo from './components/courseInfo'
// import DataList from './components/dataList'
// import PdfList from './components/pdfList'
// import VideoList from './components/videoList'
// import Practical from './components/practical'
// import Cookies from "js-cookie";
// =======
// import CourseInfo from './components/courseInfo'
import Cookies from "js-cookie";
import Directory from '../trainingcamp/components/common/directory'
import SourceManage from './sourceManage'
import Task from './components/taskList/task'
// 学情分析
import StuAnalysis from "./components/StuAnalysis.vue";
// import Train from './components/taskList/train'
// import Exam from './components/taskList/exam'
// import Interact from './components/interact'

export default {
  name: "CourseDetail",
  data(){
    return{
      Cookies,
      tabList:[
        {
          name:'章节目录',
          id:'0',
          compontName:'Directory',
          tab:'directory'
        },
        {
          name:'教学资源',
          id:'1',
          compontName:'SourceManage',
          tab:'source'
        },
        {
          name:'周课表',
          id:'2',
          compontName:'Task',
          tab:'task'
        },
        {
          name:"学情分析",
          id:"3",
          compontName:'StuAnalysis',
          tab:'analysis'
        }
        // {
        //   name:'课后练习',
        //   id:'4',
        //   compontName:'Train',
        //   tab:'train'
        // },
        // {
        //   name:'考试测试',
        //   id:'5',
        //   compontName:'Exam',
        //   tab:'exam'
        // },
        // {
        //   name:'互动答题',
        //   id:'6',
        //   compontName:'Interact',
        //   tab:'interact'
        // },
      ],
      curTab:{
        name:'目录',
        id:'0',
        compontName:'Directory',
        tab:'directory'
      },
      info:{},
      routerPath:'',
      isPrivate:true,
      lesson_levels:{},
      drawerShow:false,
      formValidate: {
        name: "",
        uploadfile_id: "",
        lesson_level: "",
        lesson_count: "",
        // lesson_week_count: '',
        group_ids: [],
        category_id: "",
        content: "",
        uploadImg: "",
      },
      ruleValidate: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        category_id: [
          { required: true, message: "请选择方向", trigger: "change" },
        ],
        lesson_level: [
          { required: true, message: "请输入等级", trigger: "blur" },
        ],
        group_ids: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        lesson_count: [
          { required: true, message: "请输入课时数", trigger: "blur" },
        ],
        uploadImg: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入课程描述", trigger: "blur" },
        ],
      },
      categoryList: [],
      levelList: [],
      drawerTitle:"",
      allowEditCategory: true,
      mapTree:[],
      total_quantity:0,
      total_enchiridion_count:0,
      categoryId:'',
      tab:'',
      dataId:'',
      categoryName:'',
      isStudent:null,
      total_ppt_count:0,
      total_video_count:0,
      total_experiment_count:0,
      total_source_link_count:0,

      liveDrawer:false,
      liveForm:{
        name:"",
        // 此处为课程id
        from_id:"",
        // 直播类型
        from_type:"0", // 0课程直播,1活动直播,2考试监控
      },
      liveRules:{
        name:[
          {required:true,message:"请输入直播间名称",trigger:"blur"}
        ],
        className:[
          {required:true,message:"请选择直播班级",trigger:"change"}
        ]
      }
    }
  },
  computed:{
    userSetting(){
      return this.$store.state.user.userSetting || {};
    }
  },
  components:{
    Directory,
    SourceManage,
    Task,
    StuAnalysis
    // Train,
    // Exam,
    // Interact
  },
  created(){
    this.dataId = this.$route.query.id || '';
    this.routerPath = this.$route.path || '';

    this.isPrivate = this.$route.query.isPrivate == '1' ? true : false;
    this.tab = this.$route.query.tab || '';
    if(this.routerPath == '/course/detail'){
      this.tabList = [
        {
          name:'目录',
          id:'0',
          compontName:'Directory',
          tab:'directory'
        }
      ]
    }
    if(this.tab){
      this.curTab = this.tabList.filter((item)=>{
        return item.tab == this.tab;
      })[0];
    }
    this.getDetail();
    this.getForm();
    this.getCategory();
    this.getGroupList();

  },
  methods:{
    confirm() {
      this.$refs["formValidate"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.formValidate.name,
            lesson_level: this.formValidate.lesson_level,
            lesson_count: this.formValidate.lesson_count,
            category_id: this.formValidate.category_id,
            group_ids: this.formValidate.group_ids,
            uploadfile_id: this.formValidate.uploadfile_id,
            content: this.formValidate.content,
            // lesson_week_count: this.formValidate.lesson_week_count
          };
          if (this.dataId) {
            //编辑
            params.id = this.dataId;
            this.api.course.courseUpdate(params).then((res) => {
              this.$Message.success("操作成功");
              // this.courseModal = false;
              this.drawerShow = false;
              this.getDetail();
            });
          }
        }
      });
    },
    // 获取班级详情
    getClassDetail() {
      let params = {
        id: this.dataId
      };
      this.api.course.courseDetail(params).then((res) => {
        this.formValidate.name = res.info.name;
        this.formValidate.lesson_level = res.info.lesson_level;
        this.formValidate.lesson_count = res.info.lesson_count;
        this.formValidate.category_id = res.info.category.id;
        this.formValidate.group_ids = res.info.group_list.map((item) => {
          return item.group_id;
        });
        this.formValidate.uploadfile_id = "";
        this.formValidate.uploadImg = res.info.map.book_img;
        this.formValidate.content = res.info.content;
        // this.formValidate.lesson_week_count = res.info.lesson_week_count;
        this.categoryName = res.info.category.name;
        this.allowEditCategory = res.allow_edit_category == '1';
      });
    },
    getForm() {
      this.api.course.courseForm().then((res) => {
        this.levelList = [];
        for (let name in res.lesson_levels) {
          this.levelList.push({
            name: res.lesson_levels[name],
            id: name,
          });
        }
      });
    },
    getCategory() {
      this.api.course.selfThirdList().then((res) => {
        this.categoryList = res.list;
      });
    },
    getGroupList() {
      let params = {
        user_id: "-1",
        pageSize: 100,
      };
      this.api.user.groupList(params).then((res) => {
        this.groupList = res.list;
      });
    },
    uploadFileSuccess(response) {
      //上传文件成功
      this.formValidate.uploadfile_id = response.data.info.id;
      this.formValidate.uploadImg = response.data.info.upload_path;
      this.$refs.formValidate.validateField("uploadfile_id");
    },
    drwaerColse() {
      this.formValidate = {
        name: "",
        uploadfile_id: "",
        lesson_level: "",
        lesson_count: "",
        // lesson_week_count: '',
        group_ids: [],
        category_id: "",
        content: "",
        uploadImg: "",
      }
      if(this.$refs.formValidate){
        this.$refs.formValidate.resetFields();
      }
      this.drawerShow = false;
    },
    goCategory() {
      this.$router.push({
        path: "/course/category",
      });
    },
    changeTab(data){
      this.curTab = data || {
        name:'学习任务',
        id:'2',
        compontName:'Task',
        tab:'task'
      };
      this.$router.push({
        path:'/teacher/course/detail',
        query:{
          tab:data ? data.tab :'directory',
          id:this.dataId,
          mapId:this.$route.query.mapId || ''
        }
      })
    },
    getDetail(){
      let params = {
        id:this.dataId
      }
      this.api.course.courseDetail(params).then((res)=>{
        this.info = res.info
        this.lesson_levels = res.lesson_levels;
        this.total_quantity = res.total_quantity;
        this.total_enchiridion_count = res.total_enchiridion_count;
        this.total_ppt_count = res.total_ppt_count;
        this.total_video_count = res.total_video_count;
        this.total_experiment_count = res.total_experiment_count;
        this.total_source_link_count = res.total_source_link_count;
        this.mapTree = res.map_tree;
        this.categoryId = res.info.category_id;
      })
    },
    edit(){
      this.drawerTitle="编辑课程-"+this.info.name;
      this.getClassDetail();
      this.drawerShow=true;
      // this.$router.push({
      //   path:'/course/create',
      //   query:{
      //     id:this.info.id,
      //     isPrivate:this.$route.query.isPrivate
      //   }
      // })
    },
    goMap(){
      this.$router.push({
        path:'/manager/dataset/mapCreate',
        query:{
          id:this.info.map_id
        }
      })
    },
    goMaterialCreate(data,type,generateDataRule){
      let params = {
        op:'create',
        generate_type:0,
        generate_id:data.id,
        type:1,
        from_type:5,
        from_id:data.id,
        generate_data_rule:2,
      };
      this.api.dataset.trainPublish(params).then((res)=>{
        this.$router.push({
          path:'/trainingcamp/materialCreate',
          query:{
            trainId:res.train_id,
            mapId:data.map_id,
            createType:3  //1 教材 2 认证 3 课程
          }
        })
      })
    },
    showLiveDrawer(){
      this.liveForm.from_id=this.info.id;
      this.liveForm.name=this.info.name;
      this.liveDrawer=true;
    },
    // 开始直播
    createLiveRoom(){
      this.$refs.liveform.validate(valid=>{
        if(valid){
          this.api.live.createLiveRoom(this.liveForm).then(()=>{
            this.liveDrawer=false;
            this.$router.push({
              path:"/liveroom-manager",
            });
          });
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.course-detail{
  padding: 20px;
  font-size: 14px;
  .chapters-detail{
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    //background: #F6F7FA;
    background: url("../../assets/images/new_icon/course_bj.png") no-repeat ;
    background-size: cover;
    border-radius: 6px;
    color:#FFFFFF;

    .chapters-detail-tit{
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;

    }
    .chapters-detail-desc{
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 30px;
    }
    .chapters-detail-nums{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .chapters-detail-num{
        margin-right: 20px;
        font-size: 14px;
      }
      .chapters-detail-nums-val{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .chapters-detail-nums-btns{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .check-source-btn{
        margin-left: 10px;
        padding: 0 20px;
        height: 30px;
        background: #333333;
        border-radius: 15px;
        font-size: 14px;
        color:#FFFFFF;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
      .live-btn{
        background-color: #E6A23C;
      }
    }
  }
  .course-detail-nr{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .course-detail-nr-top{
      margin-bottom: 20px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;
      .tit{
        font-size: 16px;
        font-weight: bold;
      }
    }
    .tab{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #EEEEEE;
      color: #666666;

      >p{
        padding-bottom: 10px;
        margin-right: 30px;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
  }
}
.course-create-nr{
  .distinguish{
    display: flex;
    align-items: center;
    margin: 20px 0;
    .distinguish-color{
      height: 10px;
      width: 20px;
      background-color: #5477F7;
      margin-right: 10px;
      margin-left: 10px;
    }
    .back-red{
      background-color: #DF4C54;
    }
    .distinguish-text{
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
